import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ContactUs from '../components/ContactUs';
import Nav from '../components/Nav';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
function Blog() {
  return (
    <div>
      <Nav />

      <header>
        <div className='shape'></div>
      </header>
      <br />
      <br />
      {/* <section className='section mt-5'>
        <div className='container'>
          <div className='row'>
            <UncontrolledButtonDropdown>
              <div
                className='btn-group btn-lg col-md-4 '
                style={{ width: '500px', height: '70px' }}
              >
                <DropdownToggle
                  color='#18A390'
                  className='btn btn-primary dropdown-toggle waves-effect waves-float waves-light btn-lg'
                  type='button'
                  id='dropdownMenuButton'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  المقالات
                </DropdownToggle>

                <DropdownMenu
                  aria-labelledby='dropdownMenuButton'
                  style={{
                    width: '500px',
                    height: '90px',
                    textAlign: 'center',
                  }}
                >
                  <option value='A' className='dropdown-item'>
                    حساب السعرات الحرارية
                  </option>

                  <option value='B' className='dropdown-item'>
                    MACROS حساب الـ
                  </option>
                </DropdownMenu>
              </div>
            </UncontrolledButtonDropdown>
          </div>
        </div>
      </section> */}

      <section className='section mt-1' id='blog'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/measure.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title' id='first'>
                    حساب السعرات الحرارية
                  </h6>
                  <Link to='/Blog1' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/diet.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title'> MACROS حساب الـ </h6>
                  <Link to='/Blog2' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/food2.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title'> الكربوهيدرات، مضرة أم ضرورية؟</h6>
                  <Link to='/Blog3' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Row 2 */}

          <div className='row'>
            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/measure.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title' id='first'>
                    اختبار: هل أنت جاهز لخسارة الوزن؟
                  </h6>
                  <Link to='/Quiz' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/diet.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title'> قـريـبًـا</h6>
                  <Link to='/Blog' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>

            <div className='col-md-4'>
              <div className='card border-0 mb-4'>
                <img
                  src='assets/imgs/food2.jpg'
                  alt
                  className='card-img-top w-100'
                />
                <div className='card-body'>
                  <h6 className='card-title'> قـريـبًـا</h6>
                  <Link to='/Blog' className='small text-muted'>
                    إنتقل الى المقال
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactUs />
    </div>
  );
}

export default Blog;
