import React from 'react'

function Q6() {
  return (
    <div class="form-check form-check mt-4">
    <input
     id="basic-cb-unchecked"
     type="checkbox"
     class="form-check-input"
   />
   <label
     for="basic-cb-unchecked"
     class="form-check-label form-label"
   >
     صحيح
   </label>
   <br />
   <input
     id="basic-cb-unchecked"
     type="checkbox"
     class="form-check-input"
   />
   <label
     for="basic-cb-unchecked"
     class="form-check-label form-label"
   >
     خـــطــــأ
   </label>
 </div>
  )
}

export default Q6