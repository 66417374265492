import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs';

function Blog2() {
  return (
    <div>
      <Nav />

      <Header />

      <section
        style={{ textAlign: 'right', color: '#18A390' }}
        className='section'
      >
        <div className='container'>
          <br />
          <Link
            to='/Blog'
            style={{ float: 'left' }}
            className='d-flex flex-row '
          >
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-chevron-left'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                />
              </svg>
            </div>
            <p className='align-top'>رجوع</p>
          </Link>
          <br />
          <h5
            className='mt-4'
            style={{ textAlign: 'center', fontSize: '25px' }}
          >
            <b>
              ! حساب السعرات الحرارية هو الإجابة التي تحتاجها لكل أسئلتك حول
              خسارة الوزن
            </b>
          </h5>
        </div>
      </section>

      <section style={{ textAlign: 'right' }}>
        <div className='container'>
          <div className='pr-md-5 mb-4 mb-md-0'>
            <img
              src='assets/imgs/vegtable.jpg'
              alt
              className='w-100 shadow-sm'
            />
          </div>
        </div>
      </section>

      <section style={{ textAlign: 'right' }} dir='rtl'>
        <div className='container'>
          <div className='col-12 mt-4  my-3' dir='rtl'>
            <br />
            <p className='blog-p'>
              من أجل الحفاظ على حمية غذائية صحية، عليك الحرص على الحصول على
              احتياجات جسمك من العناصر الغذائية الكبرى، لمعرفة ماهية العناصر
              الغذائية الكبرى والفرق بينها وبين العناصر الغذائية الصغرى بإمكانك
              الإطلاع على إدراجنا السابق في مدونة رجيم حول هذا الموضوع.
            </p>

            <p>
              <b className='section-title mb-0 blog-p'>
                لكن كيف يمكن حساب هذه الاحتياجات حتى يكون بإمكانك التأكد من ذلك؟
              </b>
            </p>

            <p className='blog-p'>
              <ol dir='rtl'>
                <li>
                  استخدام نظام أو تطبيق يعمل على حساب هذه الاحتياجات، مثل
                  <b style={{ color: '#18A390' }}> تطبيق رجيم </b>
                  التطبيق الأول في ليبيا المخصص ليكون الأداة الأمثل لمساعدتك على
                  عيش حياة صحية وتحقيق أهدافك فيما يتعلق بالوزن.
                </li>{' '}
                <br />
                <li>يمكن الحساب يدويًا من خلال الخطوات التالية :</li>
              </ol>
              <ul>
                <li>
                  أولاً، ينبغي أن تحدد عدد السعرات الحرارية التي تحتاجها في
                  اليوم، يمكنك فعل ذلك من خلال
                  <b style={{ color: '#18A390' }}> تطبيق رجيم </b>
                  أو من خلال قراءة الإدراج حول هذا الموضوع في مدونة رجيم.
                </li>
                <br />
                <li>
                  ثانيًا، عليك تحديد النسبة التي تفضلها من كل عنصر من هذه
                  العناصر، وفقًا لأهدافك في بناء جسمك، للأغلبية ستكون هذه
                  النسبة:
                  <p className='blog-p'>
                    {' '}
                    <b> 50% كربوهيدرات، 25% بروتين، 25% دهون.</b>
                  </p>
                </li>
                <li>
                  اضرب العدد الكلي للسعرات الحرارية في نسبة كل عنصر غذائي للحصول
                  على عدد السعرات التي تحتاجها من كل عنصر.
                </li>
                <br />
                <li>
                  اقسم عدد السعرات الحرارية على كمية الطاقة التي يمنحها كل عنصر
                  للحصول على احتياجاتك بالجرام.
                </li>
              </ul>
              <br />
            </p>
          </div>
        </div>
      </section>

      <section id='b5' className='section pt-4' dir='rtl'>
        <div className='container'>
          <h6 className='section-title text-center'> مثال: </h6>
          <p
            style={{ fontSize: '18px' }}
            className='section-subtitle text-center mb-5 pb-3'
          >
            لحساب احتياجات العناصر الغذائية الكبرى لشخص يستهلك 2000 سعرة حرارية
            في اليوم:
          </p>
          <div className='row' style={{ textAlign: 'right' }}>
            <div className='col-md-4'>
              <div className='card mb-4 mb-md-0'>
                <div className='card-body'>
                  <small
                    style={{ color: '#18A390' }}
                    className='text-primary font-weight-bold'
                  >
                    01
                  </small>
                  <h5 className='card-title mt-3 '>
                    {' '}
                    <b> كربوهيدرات</b>{' '}
                  </h5>
                  <h5>
                    <p style={{ fontSize: '15px' }} className='mb-0'>
                      سعرة حرارية من الكربوهيدرات، لحسابها بالجرامات نقوم بتقسيم
                      عدد السعرات على 4، وهو كمية السعرات الحرارية في جرام
                      كربوهيدرات.
                    </p>

                    <br />
                    <p>
                      {' '}
                      <b style={{ color: '#18A390' }}>
                        {' '}
                        1000 /4 = 250 جرام كربوهيدرات.
                      </b>{' '}
                    </p>
                  </h5>
                </div>
                <br />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card mb-4 mb-md-0'>
                <div className='card-body'>
                  <small
                    style={{ color: '#18A390' }}
                    className='text-primary font-weight-bold'
                  >
                    02
                  </small>
                  <h5 className='card-title mt-3'>
                    {' '}
                    <b> بروتين</b>
                  </h5>
                  <h5>
                    <p style={{ fontSize: '15px' }} className='mb-0'>
                      <b> 2000* 0.25 = 500 </b> سعرة حرارية من البروتين، لحسابها
                      بالجرامات نقوم بتقسيم عدد السعرات على 4، وهو كمية السعرات
                      الحرارية الموجودة في جرام بروتين.
                    </p>{' '}
                    <br />
                    <p>
                      {' '}
                      <b style={{ color: '#18A390' }}>
                        {' '}
                        500 / 4 = 125 جرام بروتين.
                      </b>
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card mb-4 mb-md-0'>
                <div className='card-body'>
                  <small
                    style={{ color: '#18A390' }}
                    className='text-primary font-weight-bold'
                  >
                    03
                  </small>
                  <h5 className='card-title mt-3'>
                    {' '}
                    <b> دهون</b>{' '}
                  </h5>
                  <h5>
                    <p style={{ fontSize: '15px' }} className='mb-0'>
                      <b> 2000* 0.25 = 500 </b> سعرة حرارية من الدهون، لحسابها
                      بالجرامات نقوم بتقسيم عدد السعرات على 9، وهو كمية السعرات
                      الحرارية الموجودة في جرام بروتين.
                    </p>{' '}
                    <br />
                    <p>
                      {' '}
                      <b style={{ color: '#18A390' }}>
                        {' '}
                        500 / 9 = 55,5 جرام دهون.{' '}
                      </b>
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <p className=' text-center mb-5 pb-3 mt-5 blog-p'>
            الآن كل ماعليك فعله هو متابعة عدد السعرات الحرارية خلال يومك،
            ومتابعة حجم كل وجبة وعدد جرامات كل عنصر غذائي فيها، وستصل لكل أهدافك
            فيما يتعلق بجسمك بثباث وبشكل صحي ودقيق. لو كان الموضوع يبدو معقدًا،
            أو بحاجة إلى الكثير من الوقت، يمكنك الاشتراك في تطبيق رجيم، وسيهتم
            بكل هذه التفاصيل من أجلك، كل ماعليك فعله هو تحضير وجباتك وفقًا
            لإرشادات التطبيق فقط!
          </p>
        </div>
      </section>

      {/* CONTACT */}
      <ContactUs />
    </div>
  );
}

export default Blog2;
