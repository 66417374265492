import React from 'react';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ContactUs from '../components/ContactUs';

function Blog3() {
  return (
    <div>
      <Nav />
      <Header />

      <section
        style={{ textAlign: 'right', color: '#18A390' }}
        className='section'
      >
        <div className='container'>
          <br />
          <Link
            to='/Blog'
            style={{ float: 'left' }}
            className='d-flex flex-row '
          >
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-chevron-left'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                />
              </svg>
            </div>
            <p className='align-top'>رجوع</p>
          </Link>
          <br />
          <h5
            className='mt-4'
            style={{ textAlign: 'center', fontSize: '25px' }}
          >
            <b>الكربوهيدرات، مضرة أم ضرورية؟</b>
          </h5>
        </div>
      </section>

      <section style={{ textAlign: 'right' }}>
        <div className='container'>
          <div className='pr-md-5 mb-4 mb-md-0'>
            <img src='assets/imgs/toast.jpg' alt className='w-100 shadow-sm' />
          </div>
        </div>
      </section>

      <section style={{ textAlign: 'right' }} dir='rtl' className='mb-5'>
        <div className='container'>
          <div className='col-12 mt-4  my-3' dir='rtl'>
            <br />
            <p className='blog-p'>
              عصرنا هو عصر كره الكربوهيدرات ومهاجمتها، حيث تشيع حميات عديدة تحرض
              الناس على تجنب الكربوهيدرات، أو التقليل منها، وتعمل على ربطها بمرض
              السكري وتؤكد على أن الكربوهيدرات مضرة بالصحة، فهل ينبغي عليك تجنب
              الكربوهيدرات؟
              <br />
              <br />
              في البداية وللإجابة على هذا السؤال علينا أن نعرف أن الكربوهيدرات
              من المجموعات الغذائية التي يساء فهمها بشكل كبير، أو اختزالها في
              مجموعة محددة من الأغذية، لكن الحقيقة هي أن كلمة كربوهيدرات، تشمل
              الفواكه، الخضروات، الخبز، الحلويات، العصير، وحتى مشتقات الألبان!
              <br /> <br />
              وبالتالي كما توجد أطعمة غير صحية تحتوي على الكربوهيدرات ، توجد
              أطعمة صحية وأساسية تحتوي على الكربوهيدرات.
            </p>
            <p>
              <b className='section-title mb-0 blog-p'>
                لكن ماهي الكربوهيدرات؟
              </b>
            </p>
            <p className='blog-p'>
              الكربوهيدرات هي مجموعة غذائية تشكل المصدر الرئيسي للطاقة لجسمك،
              والمصدر الرئيسي للوقود لدماغك، إذ يمكن تكسيرها للحصول على الطاقة
              اللازمة بشكل أسرع بكثير من البروتين والدهون.
              <br /> <br />
              كل مازاد نشاطك كل مازادت احتياجات جسمك من الطاقة، ليستمر في أداء
              وظائفه الحيوية بكفاءة. الطاقة التي تستهلكها اجسامنا يمكن قياسها
              بالسعرات الحرارية، والجسم يحصل على أربعة سعرات حرارية لكل جرام
              كربوهيدرات.
            </p>

            <p>
              <b className='section-title mb-0 blog-p'>
                هل كل الكربوهيدرات متماثلة؟
              </b>
            </p>
            <p className='blog-p mb-4'>
              تسمى الكربوهيدرات التي نحصل عليها من السكريات المكررة
              بالكربوهيدرات البسيطة وهي كالموجودة في الدقيق الأبيض، والبسكويت
              والحلويات والعصائر، وتسمى الكربوهيدرات التي نحصل عليها من المصادر
              الطبيعية كالحبوب الكاملة والخضروات بالكربوهيدرات المعقدة. يمكن
              أيضًا تصنيف الكربوهيدرات وفقًا للمؤشر السكري الخاص بها، أو مدى
              سرعة ارتفاع سكر الدم بعد استهلاكها.
            </p>

            <p>
              <b className='section-title mb-0 blog-p'>
                الكربوهيدرات البسيطة والكربوهيدرات المعقدة:
              </b>
            </p>

            <table className='table table-bordered blog-p mb-4'>
              <thead>
                <tr>
                  <th> الكربوهيدرات البسيطة</th>
                  <th> الكربوهيدرات المعقدة</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className='blog-p'>
                      الكربوهيدرات البسيطة هي الكربوهيدرات التي يسهل تكسيرها
                      وتحويلها إلى طاقة بسرعة، بينما الكربوهيدرات المعقدة _مثل
                      الحبوب الكاملة_ هي التي يتم تكسيرها ببطء أكثر.
                    </p>
                  </td>
                  <td>
                    <p className='blog-p'>
                      {' '}
                      تحتوي الكربوهيدرات المعقدة على محتوى أكبر من الألياف،
                      وبالتالي فإن هضمها يستغرق وقتًا أطول.
                    </p>{' '}
                  </td>
                </tr>
              </tbody>
            </table>

            <p className='blog-p'>
              محتوى الألياف المرتفع له تأثير إيجابي لسببين أولهما أنها ستشعرك
              بالشبع لفترة أطول، والثاني هو إن ارتفاع سكّر الدم بعد تناول أي
              وجبة تحتوي على كربوهيدرات معقدة يحدث بشكل منتظم ومتساو، ويمنع
              ارتفاع الأنسولين الحاد الذي يحدث بعد تناول وجبة كبيرة تحتوي على
              الكربوهيدرات البسيطة، ارتفاع الأنسولين الحاد بعد الوجبات ثم
              انخفاضه هو مايسبب الشعور بالتعب بعد الأكل مباشرة، وهو تأثير سلبي
              يربطه الناس عادة بالكربوهيدرات، لكن يمكن تجنبه عند اختيار
              الكربوهيدرات المعقدة الغنية بالألياف!
            </p>
            <p>
              <b className='section-title mb-0 blog-p'>هل الكربوهيدرات مضرة؟</b>
            </p>
            <p className='blog-p mb-5'>
              تتناول كميات كبيرة من الكربوهيدرات خاصة الكربوهيدرات البسيطة من
              الأغذية المصنعة يجعل جسمك أقل قدرة على التحكم في مستوى السكر في
              دمك. لهذا ينصح مرضى السكرّي باختيار الكربوهيدرات بحكمة.
              <br /> <br />
              كذلك فإن الإفراط في أكل الكربوهيدرات المصنعة يؤدي إلى زيادة الوزن،
              لكن علينا الأخذ في الاعتبار أن الإفراط في تناول أي مجموعة غذائية
              سيؤدي إلى زيادة الوزن، الفيصل دائمًأ هو عدد السعرات الحرارية ( وهو
              مايمكن أن يساعدك فيه
              <b style={{ color: '#18A390' }}> تطبيق رجيم! </b>
              )
              <br /> <br />
              بهذا فإن بإمكانك الاختيار لو كانت الكربوهيدرات إضافة جيدة أو مضرة
              لنظامك الغذائي، وذلك باختيار نوع الكربوهيدرات المناسب. ننصح في
              رجيم باستبدال الكربوهيدرات البسيطة بالكربوهيدرات المعقدة التي تعطي
              قدرًا أكبر من الفائدة الغذائية.
              <br /> <br />
              تذكر دائمًا الحفاظ على القاعدة الذهبية في أي رجيم:
              <b> الاعتدال يعطي أفضل النتائج وأدومها! </b>
            </p>
            <p className='blog-p'>
              <b>
                للمزيد من النصائح، ولصنع حمية صحية متوازنة، شاملة كل العناصر
                الغذائية التي تحتاجها:{' '}
              </b>

              <b style={{ color: '#18A390' }}>
                {' '}
                قم بتحميل تطبيق رجيم وتابع مقالاتنا القادمة!
              </b>
            </p>
          </div>
        </div>
      </section>

      {/* CONTACT */}
      <ContactUs />
    </div>
  );
}
export default Blog3;
