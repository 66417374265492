import axios from '../axios/index';

export async function createContactUsForm(data) {
  const url = `/contactUs`;
  const res = await axios
    .post(url, {
      data,
    })
    .then((ree) => ree)
    .catch((err) => err.response);
  if (res?.data?.success) {
    return res?.data;
  } else if (res?.data?.success === false) {
    return res?.data;
  } else {
    return 'error';
  }
}
