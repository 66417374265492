import axios from "axios";

const baseURL = "https://regime-meals-server-production.up.railway.app";

export const getMealsByName = (name) => {
    return axios({
        baseURL,
        method: "GET",
        url: `/api/v1/meals/name/${name}`,
    })
}