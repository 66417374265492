import axios from "axios";
import { useEffect } from "react";
import Home from "../pages/Home";
import Blog from "../pages/Blog";
import Blog1 from "../pages/Blog1";
import Blog2 from "../pages/Blog2";
import Blog3 from "../pages/Blog3";
import Quiz from "../pages/Quiz";
import Ios from "../pages/Ios";
import Android from "../pages/Android";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import FoodCalculator from "../pages/FoodCalculator";

function App() {
  return (
    <div className="App">
      {/* <Home /> */}

      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/calculator" exact element={<FoodCalculator />} />
          <Route path="/Blog" exact element={<Blog />} />
          <Route path="/Blog" exact element={<Blog />} />
          <Route path="/Blog1" exact element={<Blog1 />} />
          <Route path="/Blog2" exact element={<Blog2 />} />
          <Route path="/Blog3" exact element={<Blog3 />} />
          <Route path="/Quiz" exact element={<Quiz />} />
          <Route path="/ios" element={<Ios />} />
          <Route path="/android" element={<Android />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
