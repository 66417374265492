import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav
      className="navbar custom-navbar navbar-expand-md navbar-dark "
      data-spy="affix"
      data-offset-top={10}
      style={{ backgroundColor: "#D5D9DC" }}
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="assets/imgs/logo1.png" alt />
        </a>
        <button
          className="navbar-toggler ml-auto navbar-dark"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/calculator" className="nav-link">
                إحسب غذاءك
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://test.regime.ly"
                target="_blank"
                rel="noreferrer"
              >
                إختبر صحتك
              </a>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                الرئيسية{" "}
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                تواصل معنا
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;
