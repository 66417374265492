import React from 'react';

const Ios = () => {
  const redirectToIos = () => {
    window.location.href = 'https://apps.apple.com/us/app/regime/id1637106756';
  };

  redirectToIos();
  return (
    <div
      class='d-flex justify-content-center align-items-center  overflow-auto'
      style={{ height: '100vh' }}
    >
      <div class='spinner-border ' role='status'></div>
    </div>
  );
};

export default Ios;
