import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Nav from '../components/Nav';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
// import FeatherIcon from "feather-icons-react";

function Blog2() {
  return (
    <div>
      <Nav />

      <Header />

      <section
        style={{ textAlign: 'right', color: '#18A390' }}
        className='section'
      >
        <div className='container'>
          <br />
          <Link
            to='/Blog'
            style={{ float: 'left' }}
            className='d-flex flex-row '
          >
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-chevron-left'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                />
              </svg>
            </div>
            <p className='align-top'>رجوع</p>
          </Link>
          <br />
          <h5
            className='mt-4 '
            style={{ textAlign: 'center', fontSize: '25px' }}
          >
            <b>
              حساب السعرات الحرارية هو الإجابة التي تحتاجها لكل أسئلتك حول خسارة
              الوزن
            </b>
          </h5>
        </div>
      </section>

      <section style={{ textAlign: 'right' }}>
        <div className='container'>
          <div className='pr-md-5 mb-4 mb-md-0'>
            <img src='assets/imgs/cover.jpg' alt className='w-100 shadow-sm' />
          </div>
        </div>
      </section>

      <section style={{ textAlign: 'right' }} dir='rtl'>
        <div className='container'>
          <div className='col-12 mt-4  my-3' dir='rtl'>
            <br />
            <p className='blog-p'>
              كل الحميات الموجودة والشائعة وتلك التي كانت شائعة يومًا ما تعتمد
              على عنصر سحري رئيسي واحد، وهو خلق نقص في السعرات الحرارية يسمح لك
              بخسارة الوزن لذلك هذا العنصر السحري يعني أنك ببساطة، وبدون الحاجة
              لأي أدوية أو مكملات أو ، وفقط بالطعام، تستطيع الوصول إلى وزنك
              المثالي بعملية حسابية بسيطة، وباستخدام إرادتك فقط!
            </p>
            <p>
              {' '}
              <b className='section-title mb-0 blog-p'>
                {' '}
                ما هي السعرات الحرارية؟
              </b>
            </p>
            <p className='blog-p'>
              السعرات الحرارية هي وحدة قياس تستخدم لقياس كمية الطاقة التي تحصل
              عليها من الطعام، والتي يستخدمها جسمك لأداء الوظائف الحيوية اليومية
              بداية من التنفس وحتى الحركة والتمارين. عند وجود فائض من هذه الطاقة
              (أو فائض في السعرات) فإن الجسم يخزنها كدهون، وهذا هو السبب الرئيسي
              للسمنة عند الأشخاص الذين لا يعانون من أي حالات مرضية أخرى.
            </p>
            <p className='blog-p'>
              خلق نقص في السعرات الحرارية يعني استهلاك كمية من السعرات الحرارية
              أقل من احتياجات جسمك، مما يجبر جسمك لحرق مخزونه من الدهون للحصول
              على الطاقة التي يحتاجها والتي لا يحصل عليها من الطعام، حرق هذه
              الدهون يؤدي إلى خسارة الوزن، لكن، أولًا، وحتى يكون بإمكانك استهلاك
              عدد أقل من السعرات الحرارية ينبغي أن تعرف عدد السعرات الحرارية
              التي يحتاجها جسمك.
            </p>
            <p>
              <b> على سبيل المثال:</b>
              <br />
            </p>
            <p className='blog-p'>
              لو كنت تستهلك 1500 سعرة حرارية في اليوم، واحتياجات جسمك هي 2000
              سعرة حرارية، فأنك تخلق نقصًا يعادل 500 سعرة حرارية، سيحصل عليها
              جسمك من خلال حرق كمية من الدهون أو العضلات (يمكن منع خسارة العضلات
              من خلال اتباع نظام غذائي ورياضي مناسب).
            </p>
            <br />
            <p>
              {' '}
              <b className='section-title mb-0 blog-p'>
                {' '}
                كيفية حساب السعرات الحرارية التي يحتاجها جسمك؟
              </b>
            </p>
            <p className='blog-p'>
              <b> الطريقة الأولى: </b>
              بإستخدام المعادلة الحسابية التالية
            </p>
            <p className='blog-p'>
              <b> لدى النساء : &nbsp;</b>

              <b style={{ color: '#18A390' }}>
                10 * الوزن (كجم) + 6.25 * الطول (سم) - 5 * العمر بالسنوات - 161
              </b>
            </p>
            <p className='blog-p'>
              <b> لدى الرجال : &nbsp;</b>

              <b style={{ color: '#18A390' }}>
                10 * الوزن (كجم) + 6.25 * الطول (سم) - 5 * العمر بالسنوات + 5
              </b>
            </p>
            <p style={{ fontSize: '18px' }}>
              {' '}
              بعد إيجاد هذا الرقم، يتم ضربه في
              <b> معدل النشاط الجسدي </b>
              للحصول على عدد السعرات الحرارية كما موجود في الجدول:
            </p>{' '}
          </div>
        </div>
      </section>

      <section id='b3' style={{ textAlign: 'right' }}>
        <div className='container'>
          <div className='row mt-5'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>وصف درجة النشاط الجسدي</th>
                  <th>معدل النشاط</th>
                  <th>درجة النشاط الجسدي</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className='fw-bold'>
                      تقضي ساعات طويلة في الجلوس ولا تمارس أي تمارين رياضية
                    </span>
                  </td>
                  <td>1.55 </td>
                  <td>غير نشط</td>
                </tr>
                <tr>
                  <td>
                    <span className='fw-bold'>
                      عمل يتطلب الجلوس لساعات طويلة + ساعة من التمارين يوميا •
                      <br />
                      أو
                      <br />
                      عمل يتطلب الحركة + لا تمارس أي تمارين رياضية •
                    </span>
                  </td>
                  <td>1.85 </td>
                  <td>درجة متوسطة من النشاط الجسدي</td>
                </tr>
                <tr>
                  <td>
                    <span className='fw-bold'>
                      عمل يتطلب الجلوس وساعتين من التمارين الرياضية •
                      <br />
                      أو
                      <br />
                      عمل يتطلب الحركة وساعة من التمارين الرياضية •
                    </span>
                  </td>
                  <td>2.2 </td>
                  <td>درجة عالية من النشاط الجسدي</td>
                </tr>
                <tr>
                  <td>
                    <span className='fw-bold'>
                      تمارس التمارين الرياضية لأكثر من ساعتين في اليوم •
                      <br />
                      أو
                      <br />
                      لديك عمل مجهد جسديًا (حركة طيلة اليوم) + تمارس التمارين
                      الرياضية ساعة يومياً.•
                    </span>
                  </td>
                  <td>2.4 </td>
                  <td>درجة عالية جدًا من النشاط الجسدي</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section style={{ textAlign: 'right' }} className='section'>
        <div className='container'>
          <div className='container mt-5'>
            <div className='row justify-content-between'>
              <div className='col-md-6 pr-md-5 mb-4 mb-md-0'>
                <img src='assets/imgs/mobiles.svg' alt className='w-100 mt-3' />
              </div>
              <div className='col-md-6 pl-md-5'>
                <div className='row'>
                  <div className='col-12 mt-4'>
                    <h5>
                      {' '}
                      <b>الطريقة الثانية </b>: من خلال التسجيل في تطبيق رجيم
                    </h5>{' '}
                    <br />
                    <p style={{ fontSize: '18px' }}>
                      {' '}
                      باستخدام تطبيق رجيم، يمكنك من خلال استخدام حاسبة السعرات
                      الحرارية الخاصة بالتطبيق معرفة عدد السعرات الحرارية التي
                      تحتاجها يوميًا بواسطة ادخال بياناتك الخاصة مثل : الجنس،
                      الوزن، الطول، درجة النشاط الجسدي.
                    </p>
                    <br />
                    <p style={{ fontSize: '18px' }}>
                      {' '}
                      يمكن بعد حساب السعرات الحرارية بناء حمية غذائية لخلق نقص
                      في السعرات الحرارية الخاصة بك، وخسارة الوزن بشكل علمي
                      ودقيق!
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CONTACT */}
      <ContactUs />
    </div>
  );
}

export default Blog2;
