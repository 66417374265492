import React, { useState } from "react";

function Q1() {


  const [T, setT] = useState(true);
  const [F, setF] = useState(true);

  const handleChange=(data) => {
    if (data == 'T') {
      if (T == true ){
        console.log(data, 'our value')
      }
      setT(!T)
    }

    if (data == 'F') {
      if (T == F ){
        console.log(data, 'our value')
      }
      setF(!F);
    }

  }

  

  return (

    
    <div class="form-check form-check mt-4">
      <input type="checkbox" value={T} onChange={()=>handleChange('T')} id="basic-cb-unchecked" class="form-check-input" />
      <label for="basic-cb-unchecked" class="form-check-label form-label">
        صحيح
      </label>
      <br />
      <input type="checkbox" value={F} onChange={()=>handleChange('F')}  id="basic-cb-unchecked" class="form-check-input" />
      <label for="basic-cb-unchecked" class="form-check-label form-label">
        خـــطــــأ
      </label>
    </div>
  );
}

export default Q1;
