import React from 'react';
import Footer from './Footer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { createContactUsForm } from '../api';
import toast, { Toaster } from 'react-hot-toast';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().notRequired(),
  phone: yup.number().required(),
  message: yup.string().required(),
});

    // <h6 style={{ color: '#1871A3' }} className='mb-0'>
            //   رقم رجيم
            // </h6>
            // <p className='mb-4'>+21891 1111135</p>
function ContactUs() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data, e) => {
    e.preventDefault();

    createContactUsForm(data)
      .then((data) => {
        if (data.success === true) {
          setTimeout(() => {
            toast.success('تم الارسال بنجاح');
          }, 2000);
          setTimeout(() => {
            setValue('name', '', {});
            setValue('email', '', {});
            setValue('phone', '', {});
            setValue('message', '', {});
          }, 3000);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((err) => {
        toast.error('حدث خطأ ما');
      });
  };

  return (
    <section id='contact' className='section has-img-bg pb-0'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-md-5 my-3'>
        
            <h6 style={{ color: '#1871A3' }} className='mb-0'>
              صفحة الفيسبوك
            </h6>
            <a
              className='mb-4 text-white'
              href='https://www.facebook.com/regimely'
            >
              رجيم - Regime{' '}
            </a>
            <h6 style={{ color: '#1871A3' }} className='mb-0 mt-4'>
              البريد الإلكتروني
            </h6>
            <p className='mb-0'>info@regime.ly</p>
            <p />
          </div>
          <div className='col-md-7'>
            <form dir='rtl' onSubmit={handleSubmit(onSubmit)}>
              <h4
                style={{ color: '#18A390', textAlign: 'right' }}
                className='mb-4'
              >
                تواصل معنا
              </h4>
              <div className='form-row gap-3'>
                <div className='form-group col-sm-4'>
                  <input
                    type='text'
                    // className='form-control text-white rounded-0 '
                    name='name'
                    {...register('name')}
                    placeholder='الاسم'
                  />
                </div>
                <div className='form-group col-sm-4'>
                  <input
                    type='phone'
                    // className='form-control text-white rounded-0 bg-transparent'
                    name='phone'
                    {...register('phone')}
                    placeholder='رقم الهاتف'
                  />
                </div>
                <div className='form-group col-6'>
                  <textarea
                    name='message'
                    id
                    cols={30}
                    rows={2}
                    // className='form-control text-white rounded-0 bg-transparent'
                    placeholder='الرسالة'
                    {...register('message')}
                    defaultValue={''}
                  />
                </div>
                <div className='form-group col-12 mb-0'>
                  <button
                    style={{ color: "white", backgroundColor: "#28A891", borderColor: "#28A891" }}
                    type='submit'
                    className='btn btn-primary rounded w-md mt-3'>
                    إرسال
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Toaster />
        <Footer />
      </div>
    </section>
  );
}

export default ContactUs;
