import React, { useState } from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Q1 from "../components/MultiStep/Q1";
import Q2 from "../components/MultiStep/Q2";
import Q3 from "../components/MultiStep/Q3";
import Q4 from "../components/MultiStep/Q4";
import Q5 from "../components/MultiStep/Q5";
import Q6 from "../components/MultiStep/Q6";
import Q7 from "../components/MultiStep/Q7";
import Q8 from "../components/MultiStep/Q8";
import Q9 from "../components/MultiStep/Q9";
import Q10 from "../components/MultiStep/Q10";

function Quiz() {


  const [page, setPage] = useState(0);

  const theQuestion = ['هدفي هو خسارة عدد كبير من الكيلوجرامات، خسارة عدد قليل من الكيلوجرامات لن يشعرني بالسعادة.',
   'أعتقد أن الجملة : " من الأفضل خسارة الوزن تدريجيًا"'  
   
   , 
   'فكرت طويلًا في عاداتي الحياتية اليومية وتأثيرها على وزني، على سبيل المثال، عاداتي في الأكل، ومدى نشاطي وممارستي لأي نشاط جسدي.',
   ' أدرك أن خسارة الوزن تحتاج إلى تغيير دائم _مدى الحياة_  في عادات الأكل والتمرين الخاصة بي. ',
   '   أريد خسارة الوزن من أجلي أنا، وليس من أجل أي شخص آخر.',
   '        خسارة الوزن ستساعدني على التعامل مع المشاكل الموجودة في حياتي.',
   '    أنا مستعد لأكون أكثر نشاطًا بشكل دوري.',
   '  سأتمكن من خسارة الوزن فقط لو التزمت بالرجيم بشكل دائم، بدون أي تقطيع.',
   ' أدرك أن خسارة الوزن، والحفاظ على هذه الخسارة ستتطلب أن أتعلم بعض المعلومات الرئيسية حول التغذية، والتمرين، وكذلك مهارات التخطيط.',
   '   دافعي الرئيسي لخسارة الوزن هو أنني أكره شكلي.'];

  const showPage = () =>{
    if (page == 0 ) {
      return <Q1 />
    } else if (page == 1) { return <Q2 />}
    else if (page == 2) { return <Q3 />}
    else if (page == 3) { return <Q4 />}
    else if (page == 4) { return <Q5 />}
    else if (page == 5) { return <Q6 />}
    else if (page == 6) { return <Q7 />}
    else if (page == 7) { return <Q8 />}
    else if (page == 8) { return <Q9 />}
    else { return <Q10 />}
    
  }
  

  return (
    <div>
      <Nav />
      <Header />

      <section
        style={{ textAlign: "right"}}
        className="section" 
      >
        <div className="container" style={{ textAlign: "center" }}>
          <br />
          <Link to="/Blog">
            <img src="assets/imgs/chevron-left.svg" style={{ float: "left" }} />
          </Link>
          <br />
          <h4 className="mt-4" style={{color: "#18A390"}}>
            <b> هل أنت جاهز لخسارة الوزن؟ </b>
          </h4>
          <h6 className="blog-p">
            {" "}
            .الاختبار التالي سيساعدك في تجهيز نفسك بالأفكار والمعتقدات الصحيحة!
            اختر الإجابة لكل سؤال واجمع الإجابات للحصول على نتيجتك{" "}
          </h6>
        </div>
      </section>
      <section className=" mt-1">
    <div className="container" style={{ textAlign: "center" }}>
      <div className="row">
        <div className="col-md-6">
          <div className="card border-0 mb-4">
          <img
          src="assets/imgs/diet2.jpg"
          alt
          className="card-img-top w-100"
        />
          </div>
        </div>
        <div className="col-md-6">
          <div className="card border-0 mb-4">
            <div className="progress mb-5">
              <div
              style={{width: page ===0 ? '10%' : page ===1 ? '20%' : page ===2 ? '30%' : page ===3 ? '40%' : page ===4 ? '50%' : page ===5 ? '60%' : page ===6 ? '70%' : page ===7 ? '80%' : page ===8 ? '90%' : page ===9 ? '100%' : '100%'}}
                className="progress-bar bg-info"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                
              </div>
            </div>

            <h6 dir="rtl">
              {theQuestion[page]}
            </h6>
            <br />

            {showPage()}

            
              <div className="row justify-content-between mt-5">
                <button 
                disabled={page == theQuestion.length - 1}
                onClick={ () => {setPage((currentPage) => currentPage + 1 ) }}
                
                
                type="submit" className="btn btn-primary mt-3" style={{margin:'auto'}}>
                  الـــتــالــي
                </button>

                <button 
                 disabled={page == 0}
                 onClick={ () => {setPage((currentPage) => currentPage - 1 ) }}
                
                type="submit" className="btn btn-primary mt-3" style={{margin:'auto'}}>
                  السابق
                </button>
              </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
    
   
      <section id="contact" className="section has-img-bg pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 my-3">
              <h6 style={{ color: "#1871A3" }} className="mb-0">
                رقم رجيم
              </h6>
              <p className="mb-4">+21892 2222 222</p>
              <h6 style={{ color: "#1871A3" }} className="mb-0">
                صفحة الفيسبوك
              </h6>
              <p className="mb-4">رجيم - Regime </p>
              <h6 style={{ color: "#1871A3" }} className="mb-0">
                البريد الإلكتروني
              </h6>
              <p className="mb-0">Regime@gmail.com</p>
              <p />
            </div>
            <div className="col-md-7">
              <form dir="rtl">
                <h4
                  style={{ color: "#18A390", textAlign: "right" }}
                  className="mb-4"
                >
                  تواصل معنا
                </h4>
                <div className="form-row">
                  <div className="form-group col-sm-4">
                    <input
                      type="text"
                      className="form-control text-white rounded-0 bg-transparent"
                      name="name"
                      placeholder="الاسم"
                    />
                  </div>
                  <div className="form-group col-sm-4">
                    <input
                      type="email"
                      className="form-control text-white rounded-0 bg-transparent"
                      name="Email"
                      placeholder="البريد الإلكتروني"
                    />
                  </div>
                  <div className="form-group col-12">
                    <textarea
                      name="message"
                      id
                      cols={30}
                      rows={2}
                      className="form-control text-white rounded-0 bg-transparent"
                      placeholder="الرسالة"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group col-12 mb-0">
                    <button
                      type="submit"
                      className="btn btn-primary rounded w-md mt-3"
                    >
                      إرسال
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default Quiz;
