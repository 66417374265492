import React from 'react';

function Footer() {
  return (
    <footer className='mt-5 py-4 border-top border-secondary text-center'>
      <span className='mb-0 small'>
        <a>
          <b>{`Seer `}</b>
        </a>
        &copy;{` `}
        {new Date().getFullYear() + ` `}
        جميع الحقوق محفوظة
      </span>
    </footer>
  );
}

export default Footer;
