import React from 'react'

function Q10() {
  return (


    <div class="form-check form-check mt-4">
    <input
     id="basic-cb-unchecked"
     type="checkbox"
     class="form-check-input"
   />
   <label
     for="basic-cb-unchecked"
     class="form-check-label form-label"
   >
     صحيح
   </label>
   <br />
   <input
     id="basic-cb-unchecked"
     type="checkbox"
     class="form-check-input"
   />
   <label
     for="basic-cb-unchecked"
     class="form-check-label form-label"
   >
     خـــطــــأ
   </label>
 </div>
 
  //   <section className=" mt-1">
  //   <div className="container" style={{ textAlign: "center" }}>
  //     <div className="row">
  //       <div className="col-md-6">
  //         <div className="card border-0 mb-4">
  //           <img
  //             src="assets/imgs/diet2.jpg"
  //             alt
  //             className="card-img-top w-100"
  //           />
  //         </div>
  //       </div>
  //       <div className="col-md-6">
  //         <div className="card border-0 mb-4">
  //           <div className="progress mb-5">
  //             <div
  //               className="progress-bar bg-info"
  //               role="progressbar"
  //               style={{ width: "25%", color: "#1871A3" }}
  //               aria-valuenow="25"
  //               aria-valuemin="0"
  //               aria-valuemax="100"
  //             ></div>
  //           </div>

  //           <h6 dir="rtl">
  //           دافعي الرئيسي لخسارة الوزن هو أنني أكره شكلي.
  //           </h6>
  //           <br />

  //           <div class="form-check form-check mt-4">
  //             <input
  //               id="basic-cb-unchecked"
  //               type="checkbox"
  //               class="form-check-input"
  //             />
  //             <label
  //               for="basic-cb-unchecked"
  //               class="form-check-label form-label"
  //             >
  //               صحيح
  //             </label>
  //             <br />
  //             <input
  //               id="basic-cb-unchecked"
  //               type="checkbox"
  //               class="form-check-input"
  //             />
  //             <label
  //               for="basic-cb-unchecked"
  //               class="form-check-label form-label"
  //             >
  //               خـــطــــأ
  //             </label>
  //           </div>

            
  //             <div className="row justify-content-between mt-5">
  //               <button type="submit" className="btn btn-primary mt-3" style={{margin:'auto'}}>
  //                 الـــتــالــي
  //               </button>

  //               <button type="submit" className="btn btn-primary mt-3" style={{margin:'auto'}}>
  //                 السابق
  //               </button>
  //             </div>
           
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </section>
  )
}

export default Q10