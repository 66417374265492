import React from 'react';

const Android = () => {
  const redirectToAndroid = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.valak22.Regime&pli=1';
  };

  redirectToAndroid();

  return (
    <div
      class='d-flex justify-content-center align-items-center  overflow-auto'
      style={{ height: '100vh' }}
    >
      <div class='spinner-border ' role='status'></div>
    </div>
  );
};

export default Android;
