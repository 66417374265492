import React, { useEffect, useState } from "react";
import Nav from "../components/Nav";
import {  useForm } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDebounce } from "use-debounce";
import { InputAdornment, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getMealsByName } from "../api/meals";
const FoodCalculator = () => {
  const [meals, setMeals] = useState([]);
  const [currentMeal, setCurrentMeal] = useState();

  const [search, setSearch] = useState("");
  const [state, setState] = useState("init");

  const matches = useMediaQuery("(min-width:1200px)");
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      search: "",
      quantity: 1,
    },
  });
  const quantity = watch("quantity");

  const [debouncedSearch] = useDebounce(search, 500);

  useEffect(() => {
    setState("loading");
    if (debouncedSearch) {
      getMealsByName(debouncedSearch)
        .then(({ data }) => {
          console.log(data);
          setMeals(data);
          setState("done");
        })
        .catch((err) => {
          console.log(err);
          setState("error");
        });
    } else {
      setMeals([]);
      setState("done");
    }
  }, [debouncedSearch]);

  return (
    <div
      style={{
        fontFamily: "Tajawal, sans-serif",
      }}
    >
      <Nav />
      <form
        onSubmit={handleSubmit((data) => {
          console.log(data);
        })}
        dir="rtl"
      >
        {/* HINT: */}
        {/* To reduce general spacing (replace all gap-5 keywords with gap-4) */}
        <div className={`container my-4 d-flex flex-column ${matches ? "gap-5" : "gap-3"} mx-auto ${matches ? "p-4" : "p-2"} rounded`} style={{ border: "1px solid #28A891" }}>
          <Autocomplete
            onChange={(_, n) => setCurrentMeal(n)}
            onInputChange={(e) => setSearch(e.target.value)}
            // value={search}
            disablePortal
            id="meals"
            autoComplete
            getOptionLabel={(meal) => `${meal.name} - س.ح${meal.calories}`}
            sx={{ width: "80%", mx: "auto" }}
            options={meals}
            loading={state === "loading"}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                error={state === "error"}
                helperText={state === "error" && "خطأ في البحث عن الوجبة"}
                label="أدخل اسم الوجبة"
              />
            )}
          />

          {/* MAIN SECTION */}
          {currentMeal ? (
            <main className="row">
              {/* RIGHT SECTION */}
              <div
                className={`col-md-4 ${
                  matches ? "gap-5" : "gap-3"
                } d-flex flex-column`}
              >
                {/* MEAL IMAGE */}
                <img
                  style={{
                    maxWidth: "100%",
                  }}
                  src={currentMeal?.image || "/noimage.jpg"}
                  alt=""
                />

                {/* MEAL TITLE */}
                <div className="text-center h1">{currentMeal.name}</div>

                {/* START BUTTON & SECTION */}
                <div className="gap-2 text-center d-flex flex-column">
                  <div className="h6">انضم لرجيم الآن</div>
                  <div>
                    <button
                      type="button"
                      onClick={() =>
                        (window.location.href = "https://www.test.regime.ly")
                      }
                      style={{
                        color: "white",
                        backgroundColor: "#28A891",
                        borderColor: "#28A891",
                        borderRadius: 40,
                      }}
                      className="btn btn-success px-3 font-weight-bold"
                    >
                      إبدأ
                    </button>
                  </div>
                  <div> لإدارة غذائك بشكل صحي محسوب ومنظم </div>
                </div>
              </div>

              {/* LEFT SECTION */}
              <div
                className={`col-md-8 d-flex mt-3 flex-column ${
                  matches ? "gap-5" : "gap-3"
                }`}
              >
                {/* FIRST ROW */}
                <div
                  class={`d-flex d-flex flex-md-row flex-column ${
                    matches ? "gap-5" : "gap-3"
                  } h6 mx-auto`}
                >
                  <div className="form-group my-auto d-flex flex-md-row flex-column gap-3">
                    <label className="my-auto" for="exampleFormControlSelect1">
                      الكمية
                    </label>
                    <select
                      {...register("quantity", {
                        required: { value: true, message: "الكمية مطلوبة" },
                      })}
                      class
                      style={{ width: 200 }}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option value={1}>100 جم</option>
                      <option value={2}>200 جم</option>
                      <option value={3}>300 جم</option>
                      <option value={4}>400 جم</option>
                      <option value={5}>500 جم</option>
                    </select>
                  </div>

                  <div className="d-flex my-auto flex-row gap-2">
                    <div className="my-auto">السعرات الحرارية</div>
                    <div className="h4 my-auto">
                      {(currentMeal.calories * quantity).toFixed(0)} س.ح
                    </div>
                  </div>
                </div>

                <div
                  className={`d-flex text-center flex-row ${
                    matches ? "gap-5" : "gap-3"
                  } justify-content-around`}
                >
                  <div>
                    <div className="h4">
                      {(currentMeal.protein * quantity).toFixed(2)} جم
                    </div>
                    <div style={{ color: "#8F2F11" }} className="h3">
                      بروتين
                    </div>
                  </div>
                  <div>
                    <div className="h4">
                      {(currentMeal.fat * quantity).toFixed(2)} جم
                    </div>
                    <div style={{ color: "#8F7B11" }} className="h3">
                      دهن
                    </div>
                  </div>
                  <div>
                    <div className="h4">
                      {(currentMeal.carbohydrates * quantity).toFixed(2)} جم
                    </div>
                    <div style={{ color: "#588F11" }} className="h3">
                      كربوهيدرات
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <div className="h5">الأملاح المعدنية</div>
                  <table className="table table-borderless">
                    <thead style={{ backgroundColor: "#E6EAED" }}>
                      <tr>
                        {currentMeal.minerals.map(({ name }) => (
                          <th scope="col">{name}</th>
                        ))}
                        {/* <th scope="col">حديد</th>
                                            <th scope="col">فسوفور</th>
                                            <th scope="col">بوتاسيوم</th>
                                            <th scope="col">صوديوم</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {currentMeal.minerals.map(
                          ({ amount, unitOfMeasure }) => (
                            <td>
                              {(amount * quantity).toFixed(2)} {unitOfMeasure}
                            </td>
                          )
                        )}
                        {/* <td>Mark</td>
                                            <td>Mark</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-center">
                  <div className="h5">الفيتامينات</div>
                  <table className="table table-borderless">
                    <thead
                      style={{
                        backgroundColor: "#E6EAED",
                      }}
                    >
                      <tr>
                        {currentMeal.vitamins.map(({ name }) => (
                          <th scope="col">{name}</th>
                        ))}
                        {/* 
                                            <th scope="col">كالسيوم</th>
                                            <th scope="col">حديد</th>
                                            <th scope="col">فسوفور</th>
                                            <th scope="col">بوتاسيوم</th>
                                            <th scope="col">صوديوم</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {currentMeal.vitamins.map(
                          ({ amount, unitOfMeasure }) => (
                            <td>
                              {(amount * quantity).toFixed(2)} {unitOfMeasure}
                            </td>
                          )
                        )}

                        {/* <td>Mark</td>
                                            <td>Mark</td>

                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          ) : (
            <div className="d-flex gap-5 flex-column">
              <h3 className="w-50 mx-auto">
                إحسب غذاءك وتعلم المزيد عن القيمة الغذائية للوجبات العربية
                المشهورة{" "}
              </h3>
              <img
                className="mx-auto"
                width={350}
                src="empty_search_result.svg"
                alt=""
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default FoodCalculator;
