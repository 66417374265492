import React from "react";
import Blog from "./Blog";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

function Home() {
  return (
    <div>
      <nav
        className="navbar custom-navbar navbar-expand-md navbar-light "
        data-spy="affix"
        data-offset-top={10}
      >
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="assets/imgs/logo1.png" alt />
          </a>
          <button
            className="navbar-toggler ml-auto navbar-light"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            color="#D5D9DC"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/calculator" className="nav-link">
                  إحسب غذاءك
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://test.regime.ly"
                  target="_blank"
                  rel="noreferrer"
                >
                  إختبر صحتك
                </a>
              </li>
              <li className="nav-item">
                <Link to="/Blog" className="nav-link">
                  نصائح و إرشادات{" "}
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  تواصل معنا
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Section 1*/}
      <header className="header ">
        <div style={{ color: "#1871A3" }} className="overlay ">
          <h1 style={{ fontSize: "2.5rem" }}> رجيم </h1>
          <h1 className="title"> ! أخف وأحلى.. وأصح </h1>
          <br />
          {/* <p>خدمة متخصصة في تقديم المعلومات، النصائح والإرشادات الغذائية اللازمة لإتباع حمية غذائية سليمة لتحقيق أهدافك.</p> */}
          <a
            href="https://test.regime.ly"
            target="_blank"
            rel="noreferrer"
            className="ml-4 nav-link btn btn-success btn-sm rounded pl-5 pr-5 pt-3 pb-3 mt-3"
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "white",
              backgroundColor: "#28A891",
              borderColor: "#28A891",
            }}
          >
            {" "}
            إختبر صحتك{" "}
          </a>
        </div>
      </header>

      <section id="health" className="section">
        {/* pt-0 */}
        <h2 className="section-title text-center" style={{ fontSize: "2rem" }}>
          تطبيق رجيم{" "}
        </h2>
        <h5
          className="section-subtitle text-center mb-0 pb-3 pt-4"
          style={{ fontSize: "1.25rem" }}
        >
          {" "}
          ! الحل الذي تحتاجه لخسارة الوزن
        </h5>
      </section>

      <section style={{ textAlign: "right" }} className="section" id="about">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 pr-md-5 mb-4 mb-md-0">
              <img src="assets/imgs/regime-app-screenshot.png" height={500} />
            </div>
            <div className="col-md-6 pl-md-5">
              <div className="row">
                <div className="col-12 mt-4">
                  <h5 style={{ fontSize: "2rem" }} className="pb-4">
                    ما هو تطبيق رجيم؟
                  </h5>
                  <p style={{ fontSize: "1.2rem" }}>
                    معتمدين على الأسس العلمية، التكنولوجيا، وتجربة المستخدم
                    العربي. نقدم رجيم التطبيق العربي الأول الداعم لأسلوب حياة
                    صحي وتغذية متوازنة
                  </p>{" "}
                  <br />
                  <div className="d-flex justify-content-center pt-3">
                    <a
                      href="https://apps.apple.com/us/app/regime/id1637106756"
                      className=""
                    >
                      <img src="assets/imgs/App Store.png" width={150} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.valak22.Regime&pli=1"
                      className="ml-4"
                    >
                      <img src="assets/imgs/Google Play.png" width={150} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
      {/* CONTACT */}
    </div>
  );
}

export default Home;
